$(document).on('change', "#customer_employee_issued_by_user_id", function() {
  let $select = $(this),
      userId = $select.val(),
      text, labelMethod;

  if (userId == undefined || userId == "") { return false; }
  if ($select.length) {
    labelMethod = $select.data("labelMethod");
  }

  fetchUserData(userId, function(data) {
    text = labelMethod ? data[labelMethod] : data.full_name
    $("#customer_employee_issued_by").val(text).trigger("change");
  })
});

$(document).on("change", ".customer_employee_customer_employee_pp_items_personal_protective_item select, #ppi_record, .ppi-record-select", function() {
  let $select = $(this),
      text = $select.find("option:selected").text(),
      $nestedForm = $select.closest(".cb-nested-form"),
      $form = $select.closest("form"),
      nameInputSelector = ".customer_employee_customer_employee_pp_items_name input",
      $name = $nestedForm.length > 0 ? $nestedForm.find(nameInputSelector) : $form.find(nameInputSelector);

  if ($name.length) {
    $name.val(text).trigger("change")
  }
})

function fetchUserData(userId, callback) {
  if (userId == undefined || userId == "") { return false; }

  $.ajax({
    url: '/users/' + userId,
    type: 'GET',
    dataType: "JSON",
    success: function(data) {
      callback(data);
    }
  });
}
