// OVP card 11.1; 11.2; 11.3
$(document).on('change', '[id^="ovp_card_is_"][type="checkbox"]', function() {
  let valid = $('[id^="ovp_card_is_"][type="checkbox"]:checked').length > 0,
      $errorItem = $('#stateOfHealthHint').find('.has-error__item');

  if ($errorItem.length) {
    let $row = $(this).closest('.state-of-health');

    $row.toggleClass('has-error', !valid);
    $errorItem.toggleClass('cb--hidden', valid);
    $('#stateOfHealthHint').find('.hint-block').toggleClass('cb--hidden', !valid);
  }
});

// OVP summary PDF form
$(document).on('change', '#ovp_card_pdf_summary_comment_template', function() {
  let templateId = $(this).val();

  console.log({templateId});

  if (templateId > 0){
    $.ajax({
      url: '/ovp_card_summary_templates/' + templateId,
      dataType: 'JSON',
      success: function(data) {
        console.log(data);
        CKEDITOR.instances['ovp_card_pdf_summary_comment'].setData(data.description);
      }
    });
  };
});

$(document).on('change', '#ovp_card_medic_prepared_date', function () {
  $('#ovp_card_mark_as_done_date').val($(this).val());
})


// NOTE: Just before we fix every input with change/blur
function blurLabelFor() {
  for (let i = 0; i < arguments.length; i++) {
    let field = $('#' + arguments[i]).closest('.mdc-text-field')[0];
    console.log({ field });
    if (field && field.mdcTextField) field.mdcTextField.blur();
  }
}

$(document).on('change', '#ovp_card_medical_institution', function() {
  let el_value = $(this).find('option:selected').text();
  $('#ovp_card_medical_institution_title').val(el_value);
  blurLabelFor('ovp_card_medical_institution_title');
});


$(document).on('change', '#ovp_card_signatory', function() {
  var id = $(this).val();
  $.ajax({
    url: '/users/'+id,
    dataType: 'JSON',
    success: function(data) {
      $('#ovp_card_name_of_signatory').val([data['name'], data['surname']].join(' '));
      $('#ovp_card_occupation_of_signatory').val(data['occupation_name']);
      blurLabelFor('ovp_card_name_of_signatory', 'ovp_card_occupation_of_signatory');
    }
  })
});
